/**
 * Fresh Pets Context
 *
 * @export PrimaryNav
 */
import PropTypes from 'prop-types'
import React, { createContext, useContext, useState } from 'react'

// Create contexts
const AppStateContext = createContext()

const AppProvider = ( { children } ) => {
    const [ menuOpenState, setMenuOpenState ] = useState( false )

    return (
        <AppStateContext.Provider
            value={{
                menuOpen: menuOpenState,
                toggleMenu: () => setMenuOpenState( !menuOpenState ),
                stateChangeHandler: newState =>
                    setMenuOpenState( newState.isOpen )
            }}>
            {children}
        </AppStateContext.Provider>
    )
}

const useAppState = () => {
    const context = useContext( AppStateContext )
    if ( !context ) {
        throw new Error( 'useAppState must be used within an AppProvider' )
    }
    return context
}

const useApp = () => {
    return [ useAppState() ]
}

export { AppProvider, useApp }

AppProvider.propTypes = {
    children: PropTypes.node.isRequired
}
