/**
 *
 * FreshPets Border Widths
 *
 */
import space from './space'

const borderWidths = {
    base: `${space[1] / 2}px`,
    double: `${space[1]}px`
}

export default borderWidths