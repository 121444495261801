/**
 * Fresh Pets App Firebase Features
 *
 * @export FirebaseAnalytics
 */

import firebase from 'gatsby-plugin-firebase'
import { useEffect, useState } from 'react'

export const FirebaseAnalytics = () => {
    const [analyticsLoaded, setAnalyticsLoaded] = useState(false)

    useEffect(() => {
        if (analyticsLoaded === false) {
            console.log('🔥 Initializing Firebase Features 🔥')
            firebase.analytics()
            firebase.performance()
            setAnalyticsLoaded(true)
        }
    }, [analyticsLoaded])

    return null
}
