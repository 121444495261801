/** @jsx jsx */
/**
 *
 * FreshPets Meet The Team Grid
 *
 */
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import React from 'react'
import { Box, Card, Container, Flex, Heading, jsx, Paragraph } from 'theme-ui'

const MeetTheTeamGrid = ( { descriptions } ) => {
    const data = useStaticQuery( graphql`
        query {
            katieImage: file(relativePath: { eq: "katie-bentley.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: CONSTRAINED
                        width: 605
                        height: 481
                        transformOptions: {
                            duotone: {
                                highlight: "#00A29E"
                                shadow: "#192550"
                                opacity: 80
                            }
                        }
                        formats: [AUTO, WEBP]
                    )
                }
            }
            jazImage: file(relativePath: { eq: "jaz-wake.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: CONSTRAINED
                        width: 605
                        height: 481
                        transformOptions: {
                            duotone: {
                                highlight: "#00A29E"
                                shadow: "#192550"
                                opacity: 80
                            }
                        }
                        formats: [AVIF, WEBP, PNG]
                    )
                }
            }
            jemimaImage: file(relativePath: { eq: "jemima-singleton.jpeg" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: CONSTRAINED
                        width: 605
                        height: 481
                        transformOptions: {
                            duotone: {
                                highlight: "#00A29E"
                                shadow: "#192550"
                                opacity: 80
                            }
                        }
                        formats: [AVIF, WEBP, PNG]
                    )
                }
            }
            oliverImage: file(relativePath: { eq: "oliver.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: CONSTRAINED
                        width: 605
                        height: 481
                        transformOptions: {
                            duotone: {
                                highlight: "#00A29E"
                                shadow: "#192550"
                                opacity: 80
                            }
                        }
                        formats: [AVIF, WEBP, PNG]
                    )
                }
            }
        }
    ` )

    const {
        katieImage: {
            childImageSharp: { gatsbyImageData: katieImage = {} }
        },
        jazImage: {
            childImageSharp: { gatsbyImageData: jazImage = {} }
        },
        jemimaImage: {
            childImageSharp: { gatsbyImageData: jemimaImage = {} }
        },
        oliverImage: {
            childImageSharp: { gatsbyImageData: oliverImage = {} }
        }
    } = data

    return (
        <Container>
            <Flex sx={{ flexWrap: 'wrap', px: [ 0, -3 ], my: 3 }}>
                <Box
                    sx={{
                        width: [ '100%', '50%' ],
                        px: 3,
                        mb: 3
                    }}>
                    <Card>
                        <GatsbyImage
                            image={katieImage}
                            alt='Katie Bentley, Owner'
                            sx={{ borderRadius: 2, mb: 3 }} />
                        <Heading as='h3' sx={{ mb: 3 }}>
                            Katie Bentley, Owner
                        </Heading>
                        {descriptions && (
                            <React.Fragment>
                                <Paragraph
                                    sx={{ mb: 3, lineHeight: 'heading' }}>
                                    Fresh Pets owner Katie is City and Guilds
                                    level 3 qualified and has been
                                    professionally dog grooming since 2005 so
                                    your beloved pet is in safe and experienced
                                    hands.
                                </Paragraph>
                                <Paragraph sx={{ mb: 3 }}>
                                    Katie is also a member of The Groomers
                                    Spotlight, an organisation dedicated to
                                    celebrating and promoting qualifications
                                    within the grooming industry.
                                </Paragraph>
                            </React.Fragment>
                        )}
                    </Card>
                </Box>
                <Box
                    sx={{
                        width: [ '100%', '50%' ],
                        px: 3,
                        mb: 3
                    }}>
                    <Card>
                        <GatsbyImage
                            image={jazImage}
                            alt='Jaz Wake, Assistant manager'
                            sx={{ borderRadius: 2, mb: 3 }} />
                        <Heading as='h3' sx={{ mb: 3 }}>
                            Jaz Wake, Assistant manager
                        </Heading>
                        {descriptions && (
                            <React.Fragment>
                                <Paragraph sx={{ mb: 3 }}>
                                    Jaz is one of the worlds biggest dog lovers
                                    and also the proud owner of her adopted cat
                                    Sooty.
                                </Paragraph>
                                <Paragraph sx={{ mb: 3 }}>
                                    She is a founding member of the Fresh Pets
                                    team and after starting from scratch is now
                                    a super stylist with a keen eye and a calm
                                    and steady hand.
                                </Paragraph>
                            </React.Fragment>
                        )}
                    </Card>
                </Box>
                <Box
                    sx={{
                        width: [ '100%', '50%' ],
                        px: 3,
                        mb: 3
                    }}>
                    <Card>
                        <GatsbyImage
                            image={jemimaImage}
                            alt='Jemima Singleton, Grooming Assistant'
                            sx={{ borderRadius: 2, mb: 3 }} />
                        <Heading as='h3' sx={{ mb: 3 }}>
                            Jemima Singleton, Grooming Assistant
                        </Heading>
                        {descriptions && (
                            <React.Fragment>
                                <Paragraph sx={{ mb: 3 }}>
                                    Proud owner of Posey the Cockapoo, Jemima is
                                    the newest addition to the Fresh Pets team.
                                    She joined the team already with a number of
                                    canine related skills including, first aid
                                    and dog walking.
                                </Paragraph>
                                <Paragraph sx={{ mb: 3 }}>
                                    Jemima is passionate in developing her
                                    skills and knowledge in the salon as a dog
                                    groomer and loves getting to know all of our
                                    four legged customers.
                                </Paragraph>
                            </React.Fragment>
                        )}
                    </Card>
                </Box>
                <Box
                    sx={{
                        width: [ '100%', '50%' ],
                        px: 3,
                        mb: 3
                    }}>
                    <Card>
                        <GatsbyImage
                            image={oliverImage}
                            alt='Oliver, Chief Pupper'
                            sx={{ borderRadius: 2, mb: 3 }} />
                        <Heading as='h3' sx={{ mb: 3 }}>
                            Oliver, Chief Pupper
                        </Heading>
                        {descriptions && (
                            <React.Fragment>
                                <Paragraph sx={{ mb: 3 }}>
                                    Oliver is a 3 year old mixed breed,
                                    originally from Alicante -Spain. He was
                                    adopted by Katie and her husband Jim in 2019
                                    from an Island based rescue and rehoming
                                    charity.
                                </Paragraph>
                                <Paragraph sx={{ mb: 3 }}>
                                    Oliver is only 10 inches tall but thinks he
                                    is a giant. He also has a slight obsession
                                    with tennis balls and loves ALL food,
                                    especially things which are not food.
                                </Paragraph>
                            </React.Fragment>
                        )}
                    </Card>
                </Box>
            </Flex>
        </Container>
    )
}

MeetTheTeamGrid.propTypes = {
    descriptions: PropTypes.bool
}

MeetTheTeamGrid.defaultProps = {
    descriptions: false
}

export default MeetTheTeamGrid
