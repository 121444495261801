// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-faq-mdx": () => import("./../../../src/pages/faq.mdx" /* webpackChunkName: "component---src-pages-faq-mdx" */),
  "component---src-pages-gallery-mdx": () => import("./../../../src/pages/gallery.mdx" /* webpackChunkName: "component---src-pages-gallery-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-mdx": () => import("./../../../src/pages/privacy.mdx" /* webpackChunkName: "component---src-pages-privacy-mdx" */),
  "component---src-pages-services-mdx": () => import("./../../../src/pages/services.mdx" /* webpackChunkName: "component---src-pages-services-mdx" */),
  "component---src-pages-team-mdx": () => import("./../../../src/pages/team.mdx" /* webpackChunkName: "component---src-pages-team-mdx" */),
  "component---src-pages-terms-mdx": () => import("./../../../src/pages/terms.mdx" /* webpackChunkName: "component---src-pages-terms-mdx" */)
}

