/**
 * Fresh Pets SEO
 *
 * @export SEO
 */
import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'
import FreshPetsOpenGraphImage from '../../assets/images/fresh-pets-open-graph.png'
import { useSiteMetadata } from '../../hooks/use-site-metadata'

const Seo = ({ description, lang, meta, keywords, title }) => {
    const {
        title: siteMetaTitle = '',
        description: siteMetaDescription = '',
        author = '',
        siteUrl = ''
    } = useSiteMetadata()

    const metaDescription = description || siteMetaDescription

    return (
        <Helmet
            htmlAttributes={{
                lang
            }}
            title={title}
            titleTemplate={`%s | ${siteMetaTitle}`}
            meta={[
                {
                    property: 'og:url',
                    content: `${siteUrl}`
                },
                {
                    property: 'og:title',
                    content: title
                },
                {
                    name: 'description',
                    content: metaDescription
                },
                {
                    property: 'og:description',
                    content: metaDescription
                },
                {
                    property: 'og:image',
                    content: `${siteUrl}${FreshPetsOpenGraphImage}`
                },
                {
                    property: 'og:image:width',
                    content: '2400'
                },
                {
                    property: 'og:image:height',
                    content: '1260'
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
                {
                    property: 'fb:app_id',
                    content: process.env.GATSBY_FACEBOOK_APP_ID
                },
                {
                    name: 'twitter:card',
                    content: 'summary_large_image'
                },
                {
                    name: 'twitter:creator',
                    content: author
                },
                {
                    name: 'twitter:title',
                    content: title
                },
                {
                    name: 'twitter:description',
                    content: metaDescription
                }
            ]
                .concat(
                    keywords.length > 0
                        ? {
                              name: 'keywords',
                              content: keywords.join(', ')
                          }
                        : []
                )
                .concat(meta)}
        />
    )
}

Seo.defaultProps = {
    lang: 'en',
    meta: [],
    keywords: []
}

Seo.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.array,
    keywords: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string.isRequired
}

export default Seo
