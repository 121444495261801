/**
 *
 * FreshPets Border Styles
 *
 */
import colors from './colors'

const borderStyles = {
    primary: colors.primary,
    secondary: colors.secondary
}

export default  borderStyles
