/**
 * Fresh Pets Messenger Chat - Facebook Business
 *
 * @export MessengerChat
 */

import React from 'react'
import MessengerCustomerChat from 'react-messenger-customer-chat'
import { useThemeUI } from 'theme-ui'

const MessengerChat = () => {
    const context = useThemeUI()
    const {
        theme: {
            colors: { primary = '' }
        }
    } = context

    if (typeof window !== 'undefined') {
        return (
            <MessengerCustomerChat
                title='Facebook Messenger Chat'
                pageId={process.env.GATSBY_FACEBOOK_PAGE_ID}
                appId={process.env.GATSBY_FACEBOOK_APP_ID}
                themeColor={primary}
                xfbml={true}
                version='7.0'
                language='en_GB'
                loggedInGreeting='Hi! How can we help you?'
                loggedOutGreeting='Hi there! Have any questions?'
                // greetingDialogDisplay='show'
                htmlRef={window.location.pathname}
            />
        )
    }
    return null
}

export default MessengerChat
