/**
 *
 * FreshPets Site Metadata Hook
 *
 */
import { graphql, useStaticQuery } from 'gatsby'

export const useSiteMetadata = () => {
    const { site = {} } = useStaticQuery(
        graphql`
            query SITE_METADATA {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        siteUrl
                    }
                }
            }
        `
    )

    return site.siteMetadata
}
