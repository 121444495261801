/**
 *
 * FreshPets Borders
 *
 */
import borderStyles from './border-styles'
import borderWidths from './border-widths'

const borders =  {
    base: `solid ${borderWidths.base} ${borderStyles.primary}`,
    dotted: `dotted ${borderWidths.base} ${borderStyles.primary}`
}

export default borders
